if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

(function(){

    const mapView = document.querySelector('#map-view');
    const listView = document.querySelector('#list-view');

    const listContainer = document.querySelector('#locations-list');
    const mapContainer = document.querySelector('#locations-map');

    if(mapView) {
        mapView.addEventListener("click", () => {
            listContainer.classList.remove('active');
            listView.classList.remove('active');
            mapContainer.classList.add('active');
            mapView.classList.add('active');
        });
    };
    if(listView) {
        listView.addEventListener("click", () => {
            mapContainer.classList.remove('active');
            mapView.classList.remove('active');
            listContainer.classList.add('active');
            listView.classList.add('active');
        });
        if (window.matchMedia("(max-width: 1025px)").matches) {
            listContainer.classList.remove('active');
            listView.classList.remove('active');
            mapContainer.classList.add('active');
        };
    };
    

    // Remove empty <p> tags created by tinymce
    const paragraph = document.querySelectorAll('p') || [];
	paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));


    $('.sym-slides').slick({
        accessibility: false
    });

    $('.slides-venues').slick({
        dots: true,
        accessibility: false,
        fade:true, 
        prevArrow:".venues-prev",
        nextArrow:".venues-next",
    });

    $('.fancybox').fancybox();

    $('[data-slick-goto]').click(function () {
		const $curSlider = $(this).data('slick-target') ? $($(this).data('slick-target')) : $(this).closest('.sym-slides');
		const target = $(this).data('slick-goto') ? parseInt($(this).data('slick-goto')) : 0;

		$curSlider.slick('slickGoTo', target);
		$(this).siblings('[data-slick-goto]').removeClass('js-slick_active active');
		$(this).addClass('js-slick_active active');
	});

    $("#arrows .slick-prev").click(function(){
        $(".gallery-slider").slick("slickPrev");
    });
    $("#arrows .slick-next").click(function(){
        $(".gallery-slider").slick("slickNext");
    });

    $('#init-slick').click(function(){
        $('.floorplan-slider').slick({
            fade: true, 
            prevArrow: '.prev',
            nextArrow: '.next',
            responsive: [
                {
                breakpoint: 600, 
                settings: {
                    arrows: false,
                    dots: true
                    }
                }
            ]
        });
    });

    
    const animateHTML = function () {
        let elems,
            elemsShow,
            windowHeight;
        let init = function () {
            elems = document.getElementsByClassName('hide-object');
            elemsShow = document.getElementsByClassName('show-object');
            windowHeight = window.innerHeight;
            addEventHandlers();
        }; 
        let addEventHandlers = function () {
            window.addEventListener('scroll', checkPosition);
            window.addEventListener('resize', init);
        };
        let checkPosition = function () {
            for (let i = 0; i < elems.length; i++) {
                let posFromTop = elems[i].getBoundingClientRect().top;
                if (posFromTop - windowHeight <= 0) {
                    elems[i].className = elems[i].className.replace('hide-object', 'show-object')
                }
            } 
            for (let i = 0; i < elemsShow.length; i++) {
                let posFromTop = elemsShow[i].getBoundingClientRect().top;
                if (posFromTop - windowHeight >= 0) {
                    elemsShow[i].className = elemsShow[i].className.replace('show-object', 'hide-object')
                } 
            } 
        };
        return {
            init: init 
        }
    };
    animateHTML().init();

})();

document.addEventListener('DOMContentLoaded', () => {
    
    if( window.location.hash ){
        let elementInDOMFromHashInURL = document.querySelector(`${window.location.hash}`);
        let hrefInAnchorsFromHasInURL = document.querySelector(`a[href="${window.location.hash}"]`);
        if( hrefInAnchorsFromHasInURL ){
            hrefInAnchorsFromHasInURL.scrollIntoView({ 
                behavior: "smooth",
                block: "center"
            });
            setTimeout(()=> {
                hrefInAnchorsFromHasInURL.click();
            }, 1000);
        }else if( elementInDOMFromHashInURL ){
            elementInDOMFromHashInURL.scrollIntoView({ 
                behavior: "smooth",
                block: "center"
            });
        }
    }

});